<template>
  <div class="exportable-pie-chart">
    <pie-chart class="chart-container" :chart-data="chartData" :options="options" />
    <button @click.prevent="exportData" class="button download-button"><img class="icon" src="/icons/download.svg"></button>
  </div>
</template>

<script>
import PieChart from '@/components/shared/charts/PieChart'
import exportService from '@/services/export-service'
import C2S from 'canvas2svg'
import Chart from 'chart.js'

export default {
  components: {
    PieChart
  },
  props: {
    chartData: {
      type: Object
    },
    options: {
      type: Object
    },
    groupingName: { // e.g. Usage Type
      type: String
    }
  },
  methods: {
    createSvg () {
      // Testspace for exporting SVG. Doesnt work yet...

      var svgContext = C2S(600, 600)
      let options = { ...this.options }
      // deactivate responsiveness and animation
      options.responsive = false
      options.animation = false
      let graphData = {
        'type': 'bar',
        'data': this.chartData,
        'options': options
      }

      var mySvg = new Chart(svgContext, graphData)
      mySvg.width = 600
      mySvg.height = 600
    },
    exportData () {
      let data = exportService.pieChartToCsv(this.chartData, this.groupingName)
      exportService.saveCsv(data.join('\n'), 'export.csv')
    }
  }
}
</script>
<style>
.exportable-pie-chart {
  position: relative;
}

.exportable-pie-chart .download-button {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
}

.exportable-pie-chart .chart-container {
  position: relative;
}
</style>
