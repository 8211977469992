<template>
  <button @click.prevent="reverseOrder()" class="button">
    <img class="icon" src="/icons/arrow-up.svg" v-show="!query.sort_reverse">
    <img class="icon" src="/icons/arrow-down.svg" v-show="query.sort_reverse">
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      query: 'portfolio/query'
    })
  },
  methods: {
    ...mapActions({
      setQuery: 'portfolio/setQuery'
    }),
    reverseOrder () {
      let query = { ...this.query }
      query.sort_reverse = !query.sort_reverse
      this.setQuery({
        'portfolioId': this.portfolio.id,
        'query': query
      })
    }
  }
}
</script>
