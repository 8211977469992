<template>
  <section class="tag-filters">
    <span v-for="(tagGroup, index) in portfolio.tag_groups" :key="index">
      <tag-group-dropdown :portfolio="portfolio" :tag-group="tagGroup" class="tag-group-dropdown" />
    </span>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import TagGroupDropdown from '@/components/portfolio/filters/TagGroupDropdown'

export default {
  props: {
    portfolio: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      query: 'portfolio/query'
    }),
    hasActiveFilters () {
      if (this.query === undefined || !this.query.filters) {
        return false
      }
      for (const values of Object.values(this.query.filters)) {
        if (values.length) {
          return true
        }
      }
      return false
    }
  },
  components: {
    TagGroupDropdown
  }
}
</script>
<style>
.tag-filters {
  display: inline-block;
  vertical-align: top;
}

.tag-filters .tag-group-dropdown {
  display: inline-block;
  margin-right: var(--spacing-xs);
  vertical-align: top;
}
</style>
