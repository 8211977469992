<i18n>
{
  "de": {
    "pageTitle": "Liegenschaften",
    "mapButton": "Karte",
    "chartsButton": "Kennzahlen"
  }
}
</i18n>

<template>
  <main-layout :portfolio="portfolio" :ready="portfolioReady && portfolioBuildingsReady" class="portfolio-page">
    <template #default>
      <div class="left-column">
        <h1>{{ portfolio.name }} - {{ $t('pageTitle') }}</h1>
        <div class="list-controls">
          <div v-if="portfolio.buildings_count > 0">
            <portfolio-search :portfolio="portfolio" class="portfolio-search" />
            <tag-filters class="tag-filters" :portfolio="portfolio" />
          </div>
          <nav class="round-buttons">
            <a href="#" @click.prevent="setLeftColumnMode('cards')" :class="navCardsClass"><img src="/icons/credit-card.svg"></a>
            <a href="#" @click.prevent="setLeftColumnMode('table')" :class="navTableClass"><img src="/icons/align-justify.svg"></a>
          </nav>
        </div>
        <portfolio-list class="portfolio-results" :portfolio="portfolio" :search-text="query.search" :as-table="leftColumnMode === 'table'" />
      </div>
      <div class="right-column right-column-map" v-if="rightColumnMode === 'map'">
        <nav class="round-buttons">
          <a href="#" @click.prevent="setRightColumnMode('map')" :class="navMapClass"><img src="/icons/map.svg"><span>{{ $t('mapButton') }}</span></a>
          <a href="#" @click.prevent="setRightColumnMode('bubbles')" :class="navBubblesClass"><img src="/icons/pie-chart.svg"><span>{{ $t('chartsButton') }}</span></a>
        </nav>
        <portfolio-map :buildings="portfolio.buildings" :portfolio="portfolio" v-show="rightColumnMode === 'map'" />
        <div v-show="rightColumnMode === 'bubbles'" class="portfolio-bubbles">
          <portfolio-kpi-overview :portfolio="portfolio" :show="rightColumnMode === 'bubbles'" />
        </div>
      </div>
      <div class="right-column right-column-charts" v-if="rightColumnMode === 'bubbles'">
        <nav class="round-buttons">
          <a href="#" @click.prevent="setRightColumnMode('map')" :class="navMapClass"><img src="/icons/map.svg"><span>{{ $t('mapButton') }}</span></a>
          <a href="#" @click.prevent="setRightColumnMode('bubbles')" :class="navBubblesClass"><img src="/icons/pie-chart.svg"><span>{{ $t('chartsButton') }}</span></a>
        </nav>
        <portfolio-map :buildings="portfolio.buildings" :portfolio="portfolio" v-show="rightColumnMode === 'map'" />
        <div v-show="rightColumnMode === 'bubbles'" class="portfolio-bubbles">
          <portfolio-kpi-overview :portfolio="portfolio" :show="rightColumnMode === 'bubbles'" />
        </div>
      </div>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'
import PortfolioBuildingsMixin from '@/pages/vuex-mixins/PortfolioBuildingsMixin'

import PortfolioSearch from '@/components/portfolio/PortfolioSearch'
import TagFilters from '@/components/portfolio/filters/TagFilters'
import PortfolioList from '@/components/portfolio/PortfolioList'
import PortfolioMap from '@/components/portfolio/PortfolioMap'
import PortfolioKpiOverview from '@/components/portfolio/kpi/PortfolioKpiOverview'

import { mapGetters } from 'vuex'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioBuildingsMixin // Provides: portfolio.buildings, portfolioBuildingsLoading, portfolioBuildingsReady
  ],
  data () {
    return {
      leftColumnMode: 'cards',
      rightColumnMode: 'map'
      // scrollY: 0
    }
  },
  name: 'portfolio-page',
  computed: {
    ...mapGetters({
      query: 'portfolio/query'
    }),
    navTableClass () {
      return {
        'selected': this.leftColumnMode === 'table'
      }
    },
    navCardsClass () {
      return {
        'selected': this.leftColumnMode === 'cards'
      }
    },
    navMapClass () {
      return {
        'selected': this.rightColumnMode === 'map'
      }
    },
    navBubblesClass () {
      return {
        'selected': this.rightColumnMode === 'bubbles'
      }
    }
  },
  /* mounted () {
    window.addEventListener('scroll', (event) => {
      this.scrollY = Math.round(window.scrollY)
    })
  }, */
  methods: {
    setLeftColumnMode (mode) {
      this.leftColumnMode = mode
    },
    setRightColumnMode (mode) {
      this.rightColumnMode = mode
    }
  },
  components: {
    MainLayout,
    PortfolioSearch,
    TagFilters,
    PortfolioList,
    PortfolioMap,
    PortfolioKpiOverview
  }
}
</script>

<style>

.portfolio-results {
  --grid-template-s: 1fr;
  --grid-template-m: repeat(auto-fill, minmax(var(--default-column-width), 1fr));
}

.portfolio-page > .sim-warning {
  padding: var(--spacing-l);
}

.portfolio-page h1,
.portfolio-page nav.round-buttons {
  align-self: baseline;
}

.portfolio-page nav.round-buttons {
  justify-self: end;
  grid-column-end: -1;
  font-weight: 600;
  color: var(--highlight-color);
  letter-spacing: .01em;
  font-feature-settings: "kern", "liga", "clig", "calt", "c2sc", "smcp";
}

.portfolio-page .right-column nav.round-buttons {
  position:absolute;
  right: 1rem;
  top: 1rem;
  z-index: 3;
}

.portfolio-page nav.round-buttons a {
  display: inline-block;
  padding: var(--spacing-s);
  margin: var(--spacing-xs);
  border: 1px solid var(--hairline-color);
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
  border-radius: 500px;
  line-height: 0;
}

.portfolio-page nav.round-buttons a.selected {
  border: 2px solid hsl(202, 92%, 14%);
  /* background-color:hsl(202, 92%, 90%); */
}

.portfolio-page nav.round-buttons a img {
  height: 16px;
  width: 16px;
  vertical-align: sub;
  margin-right: 5px;
}

.portfolio-page nav.round-buttons a:hover {
  box-shadow: 0px 0px 4px 2px var(--shadow-color);
}

.portfolio-page .portfolio-page-wrapper {
  position: relative;
}

.portfolio-page .left-column {
  position: relative;
}

.portfolio-page .list-controls {
  display: grid;
  grid-template-columns: auto max-content;
}

.portfolio-page .tag-filters {
  padding-top: var(--spacing-m);
}

.portfolio-page .right-column.right-column-map {
  position: absolute;
  top: calc(var(--header-height) + 2px);
  right: 0;
  width: calc(38vw - 30px);
  height: calc(100vh - var(--sticky-header-height));
}

.portfolio-page .right-column.right-column-charts {
  position: absolute;
  top: calc(var(--header-height) + 2px);
  right: 0;
  width: calc(38vw - 30px);
}

.portfolio-page .right-column {
  display: none;
}

@media screen and (min-width: 75.2rem) {
  .portfolio-page .right-column {
    display: block;
  }
  .portfolio-page .left-column {
    width: calc(62vw - 10px);
  }
}

.portfolio-page .portfolio-bubbles {
  margin-right: var(--spacing-m);
  margin-top: 50px;
}

.sticky-header .portfolio-page .right-column.right-column-map {
  position: fixed;
  top: 50px;
  right: 0;
}

@media screen and (min-width: 18.4rem) {
  .portfolio-results {
    grid-template-columns: var(--grid-template-m);
  }
}

</style>
