<template>
  <svg class="ring-indicator" :viewBox="`0 0 ${size} ${size}`">
    <circle class="ring-track" :cx="center" :cy="center" :r="radius" />
    <circle v-if="year" class="ring-progress" :class="progressClasses" :cx="center" :cy="center" :r="radius" :style="progressStyles" />
  </svg>
</template>

<script>

export default {
  props: {
    year: {
      type: Number
    },
    minLifetime: {
      type: Number
    },
    maxLifetime: {
      type: Number
    }
  },
  data () {
    return {
      size: 400,
      radius: 170,
      circle: { color: 'xd', opacity: 0.5, angle: 360, offset: 0 }
    }
  },
  computed: {
    center () {
      return this.size / 2
    },
    progressClasses () {
      const currentYear = new Date().getFullYear()
      return {
        'progress-high': currentYear >= this.maxLifetime,
        'progress-med': currentYear >= this.minLifetime && currentYear < this.maxLifetime,
        'progress-low': currentYear < this.minLifetime
      }
    },
    progressStyles () {
      const currentYear = new Date().getFullYear()
      const circumference = 2 * Math.PI * this.radius
      const strokeLength = circumference * Math.min(1.0, (currentYear - this.year) / (this.maxLifetime - this.year))
      return {
        'stroke-dasharray': `${strokeLength} ${circumference - strokeLength}`,
        'stroke-dashoffset': circumference / 4
      }
    }
  }
}
</script>

<style>
.ring-indicator {
  fill-rule: evenodd;
  clip-rule: evenodd;
  max-width: 100%;

  --circle-stroke-width: 60;
}

.ring-indicator circle {
  fill: none;
  stroke: currentColor;
  stroke-width: var(--circle-stroke-width);
}

.ring-indicator .ring-track {
  color: var(--track-color);
}

.ring-indicator .ring-progress {
  color: transparent;
}

.ring-indicator .ring-progress.progress-high {
  color: var(--error-color);
}

.ring-indicator .ring-progress.progress-med {
  color: var(--warning-color);
}

.ring-indicator .ring-progress.progress-low {
  color: var(--success-color);
}
</style>
