<i18n>
{
  "de": {
    "searchFieldPlaceholder": "Suche nach Adresse, Ort, Bezeichnung oder Nummer",
    "searchButton": "Suchen"
  }
}
</i18n>

<template>
  <form class="portfolio-search">
    <input id="portfolio-search-field" v-model="model" type="text" @keyup.esc="escape" :placeholder="$t('searchFieldPlaceholder')" v-focus />
    <button class="button" @click.prevent="updateSearchText">{{ $t('searchButton') }}</button>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      query: 'portfolio/query'
    })
  },
  data () {
    return {
      model: null
    }
  },
  watch: {
    query () {
      this.model = this.query.search
    }
  },
  mounted () {
    this.model = this.query.search
  },
  methods: {
    ...mapActions({
      setQuery: 'portfolio/setQuery'
    }),
    updateSearchText (event) {
      let query = { ...this.query }
      query.search = this.model
      this.setQuery({
        'portfolioId': this.portfolio.id,
        'query': query
      })
    },
    escape (event) {
      event.target.value = ''
      let query = { ...this.query }
      query.search = ''
      this.setQuery({
        'portfolioId': this.portfolio.id,
        'query': query
      })
    }
  }
}
</script>

<style>
.portfolio-search input {
  width: 100%;
  max-width: 500px;
  margin-right: var(--spacing-m);
}

.portfolio-search input:focus {
  border: 1px solid #aaa;
}
</style>
