<i18n>
{
  "de": {
    "sortOptions": {
      "identifier": "Identifikator",
      "municipality": "Gemeinde",
      "description": "Beschreibung",
      "building_year": "Baujahr",
      "energy_area": "Energiebezugsfläche",
      "ghg": "GHG-Emissionen",
      "pe": "Primärenergie"
    }
  }
}
</i18n>

<template>
  <dropdown-menu class="sort-dropdown">
    <template v-slot:title>
      Sortieren: {{ $t(`sortOptions.${query.sort_order}`) }}
    </template>
    <template #contents>
      <li v-for="option in sortOrderOptions" :key="option">
        <a href="#" @click.prevent="updateSortOrder(option)">{{ $t(`sortOptions.${option}`) }}</a>
      </li>
    </template>
  </dropdown-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DropdownMenu from '@/components/shared/DropdownMenu'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      query: 'portfolio/query'
    }),
    sortOrderOptions () {
      return ['identifier', 'municipality', 'description', 'building_year', 'energy_area', 'ghg', 'pe']
    }
  },
  methods: {
    ...mapActions({
      setQuery: 'portfolio/setQuery'
    }),
    updateSortOrder (sortAttribute) {
      let query = { ...this.query }
      query.sort_order = sortAttribute
      this.setQuery({
        'portfolioId': this.portfolio.id,
        'query': query
      })
    }
  },
  components: {
    DropdownMenu
  }
}
</script>

<style>
.sort-dropdown {
  display: inline-block;
  vertical-align: middle;
}
</style>
