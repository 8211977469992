<i18n>
{
  "de": {
    "openDetailsLink": "Details anzeigen"
  }
}
</i18n>
<template>
  <div class="portfolio-map">
    <MglMap class="map" :scrollZoom="false" :accessToken="accessToken" :mapStyle.sync="mapStyle" :center="coordinates" :zoom="initialZoom">
      <!-- Adding navigation control -->
      <MglNavigationControl position="top-left" />
      <!-- Adding GeoJSON layer -->
      <MglGeojsonLayer
        sourceId="buildings"
        :source="buildingsSource"
        layerId="clustersLayer"
        :layer="clustersLayer"
      />
      <MglGeojsonLayer
        sourceId="buildings"
        layerId="clusterCountLayer"
        :layer="clusterCountLayer"
      />
      <MglGeojsonLayer
        sourceId="buildings"
        :source="buildingsSource"
        layerId="unclusteredPointsLayer"
        :layer="unclusteredPointsLayer"
        @click="layerClick"
        @mousemove="mouseEvent"
        @mouseleave="mouseEvent"
      />
      <MapboxPopup
        :coordinates="popup.coordinates"
        :showed="popup.showed"
        anchor="top"
        @close="onPopupClose"
        class="map-popup"
      >
        <div>
          <div v-if="activeFeature !== undefined">
            <portfolio-list-item
              :key="activeFeature.properties.id"
              :building="buildings.find(b => b.id == activeFeature.properties.id)"
              :portfolio="portfolio"
            />
          </div>
        </div>
      </MapboxPopup>
    </MglMap>
  </div>
</template>

<script>
import { MglMap, MglNavigationControl, MglGeojsonLayer } from 'vue-mapbox'
import MapboxPopup from '@/components/shared/MapboxPopup'
import PortfolioListItem from '@/components/portfolio/PortfolioListItem'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    buildings: {
      type: Array,
      required: true
    }
  },
  name: 'portfolio-map',
  components: {
    MglMap,
    MapboxPopup,
    MglNavigationControl,
    PortfolioListItem,
    MglGeojsonLayer
  },
  data () {
    return {
      accessToken: 'pk.eyJ1IjoicnVnZ2xlIiwiYSI6ImNrNjllYnhpcTBkdDAzbXBtZXd4OTlhazAifQ.94w7D1gcvHtzyGlM58VFog',
      mapStyle: 'mapbox://styles/ruggle/ckaxzpq0l0f5f1ipctig4ncfk',
      hoverFeature: undefined,
      activeFeature: undefined,
      popup: {
        coordinates: [8, 48], // this can't be blank!  it won't be shown but pick something
        showed: false
      }
    }
  },
  methods: {
    onPopupClose (event) {
      this.popup.showed = false
      this.activeFeature = undefined
    },
    layerClick (event) {
      let features = event.map.queryRenderedFeatures(event.mapboxEvent.point, {
        layers: ['unclusteredPointsLayer']
      })
      if (features.length > 0) {
        this.activeFeature = features[0]
        this.$router.push({ name: 'building', params: { portfolio_id: this.portfolio.id, building_id: features[0].id } })
      }
    },
    mouseEvent (event) {
      let features = event.map.queryRenderedFeatures(event.mapboxEvent.point, {
        layers: ['unclusteredPointsLayer']
      })
      if (this.hoverFeature !== undefined) {
        event.map.setFeatureState(
          { source: 'buildings', id: this.hoverFeature.id },
          { hover: false }
        )
        this.popup.showed = false
      }
      if (features.length > 0) {
        this.hoverFeature = features[0]
        event.map.setFeatureState(
          { source: 'buildings', id: this.hoverFeature.id },
          { hover: true }
        )
        this.activeFeature = features[0]
        this.popup.coordinates = this.activeFeature.geometry.coordinates
        this.popup.showed = true
      } else {
        this.hoverFeature = undefined
      }
    }
  },
  computed: {
    coordinates () {
      return [8, 46.7]
    },
    initialZoom () {
      return 6
    },
    buildingsSource () {
      var features = []
      for (var i = 0; i < this.buildings.length; i++) {
        if (this.buildings[i].latitude !== undefined && this.buildings[i].longitude !== undefined) {
          features.push({
            'id': this.buildings[i]['id'],
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [this.buildings[i].longitude, this.buildings[i].latitude]
            },
            'properties': {
              'id': this.buildings[i].id
            }
          })
        }
      }
      return {
        'type': 'geojson',
        'cluster': true,
        'clusterMaxZoom': 8, // Max zoom to cluster points on
        'clusterRadius': 50, // Radius of each cluster when clustering points (defaults to 50)
        'data': {
          'type': 'FeatureCollection',
          'features': features
        }
      }
    },
    unclusteredPointsLayer () {
      return {
        type: 'circle',
        source: 'buildings',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-radius': 4,
          'circle-stroke-opacity': 0.3,
          'circle-stroke-color': '#002B45',
          'circle-color': '#002B45',
          'circle-stroke-width': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            8,
            6
          ]
        }
      }
    },
    clusterCountLayer () {
      return {
        type: 'symbol',
        source: 'buildings',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        },
        paint: {
          'text-color': '#ffffff'
        }
      }
    },
    clustersLayer () {
      return {
        'type': 'circle',
        'source': 'buildings',
        'filter': ['has', 'point_count'],
        'paint': {
          'circle-color': '#002B45',
          'circle-stroke-width': 2,
          'circle-stroke-opacity': 0.3,
          'circle-stroke-color': '#002B45',
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            Math.ceil(this.buildings.length / 10), 30,
            Math.ceil(this.buildings.length / 5) + 1, 40
          ]
        }
      }
    }
  }
}
</script>

<style>
.portfolio-map {
  height: 100%;
}

.portfolio-map .map-popup {
  min-width:400px;
}
</style>
