<i18n>
{
  "de": {
    "heatingAge": "Alter Heizung",
    "wallsAge": "Alter Fassade",
    "windowsAge": "Alter Fenster",
    "basementAge": "Alter Kellerdecke",
    "roofAge": "Alter Dach",
    "years": "Jahre",
    "yearsUpTo": "bis",
    "lifecyclePopupTitle": "Zustand Gebäudehülle und Heizung",
    "unknownCanton": "Standort?",
    "missingArea": "Fläche?"
  }
}
</i18n>

<template>
  <li class="portfolio-list-item">
    <router-link :to="{ name: 'building', params: { portfolio_id: portfolio.id, building_id: building.id }}">
      <section>
        <header>
          <div class="building-id" v-match-text="searchText">{{ building.identifier }}</div>
          <v-popover trigger="hover" placement="right">
            <ring-indicator :year="building.heating_year" :minLifetime="building.heating_replacement_year" :maxLifetime="building.heating_replacement_year + 5" class="ring-indicator" />
            <ring-indicator :year="building.walls_year" :minLifetime="building.walls_replacement_year" :maxLifetime="building.walls_replacement_year + 5" class="ring-indicator" />
            <ring-indicator :year="building.windows_year" :minLifetime="building.windows_replacement_year" :maxLifetime="building.windows_replacement_year + 5" class="ring-indicator" />
            <ring-indicator :year="building.roof_year" :minLifetime="building.roof_replacement_year" :maxLifetime="building.roof_replacement_year + 5" class="ring-indicator" />
            <ring-indicator :year="building.basement_year" :minLifetime="building.basement_replacement_year" :maxLifetime="building.basement_replacement_year + 5" class="ring-indicator" />
            <template slot="popover">
              <header>
                {{ $t('lifecyclePopupTitle') }}
              </header>
              <main class="portfolio-list-item-lifecycle">
                <ring-indicator :year="building.heating_year" :minLifetime="building.heating_replacement_year" :maxLifetime="building.heating_replacement_year + 5" />
                {{ $t('heatingAge') }}: {{ heatingAge }} {{ $t('years') }}
              </main>
              <main class="portfolio-list-item-lifecycle">
                <ring-indicator :year="building.windows_year" :minLifetime="building.windows_replacement_year" :maxLifetime="building.windows_replacement_year + 5" />
                {{ $t('windowsAge') }}: {{ windowsAge }} {{ $t('years') }}
              </main>
              <main class="portfolio-list-item-lifecycle">
                <ring-indicator :year="building.walls_year" :minLifetime="building.walls_replacement_year" :maxLifetime="building.walls_replacement_year + 5" />
                {{ $t('wallsAge') }}: {{ wallsAge }} {{ $t('years') }}
              </main>
              <main class="portfolio-list-item-lifecycle">
                <ring-indicator :year="building.roof_year" :minLifetime="building.roof_replacement_year" :maxLifetime="building.roof_replacement_year + 5" />
                {{ $t('roofAge') }}: {{ roofAge }} {{ $t('years') }}
              </main>
              <main class="portfolio-list-item-lifecycle">
                <ring-indicator :year="building.basement_year" :minLifetime="building.basement_replacement_year" :maxLifetime="building.basement_replacement_year + 5" />
                {{ $t('basementAge') }}: {{ basementAge }} {{ $t('years') }}
              </main>
            </template>
          </v-popover>
        </header>
        <main>
          <div class="building-description">
            <div v-match-text="searchText">{{ building.description }}</div>
            <div v-match-text="searchText">{{ building.zip_code }} {{ building.municipality }}</div>
            <!-- <div class="gis-errors" v-if="building.gwr_state !== 'OK'">{{ $t(`_gwrState.${building.gwr_state}`) }}</div> -->
            <div class="gis-errors" v-if="building.gwr_state === 'OK' && building.cadastre_state !== 'OK'">{{ $t(`_cadastreState.${building.cadastre_state}`) }}</div>
            <div class="gis-errors" v-if="building.gwr_state === 'OK' && building.geothermal_state && building.geothermal_state !== 'OK'">{{ $t(`_geothermalState.${building.geothermal_state}`) }}</div>
            <div class="gis-errors" v-if="building.gwr_state === 'OK' && building.energy_grids_state && building.energy_grids_state !== 'OK'">{{ $t(`_energyGridsState.${building.energy_grids_state}`) }}</div>
            <div class="gis-errors" v-if="building.gwr_state === 'OK' && building.roof_state !== 'OK'">{{ $t(`_roofState.${building.roof_state}`) }}</div>
          </div>
        </main>
      </section>
      <footer>
        <ul class="building-kpis">
          <li><span>
            <span v-if="building.data_quality.energy_area === 'GIS_ESTIMATE'" class="numbers missing-data">{{ formatNumber(building.energy_area, 0) }} m<sup>2</sup></span>
            <span class="numbers" v-else-if="building.energy_area">{{ formatNumber(building.energy_area, 0) }} m<sup>2</sup></span>
            <span v-else class="missing-data">{{ $t('missingArea') }}</span>
          </span></li>
          <li><span><span class="numbers" v-if="ghgValue">{{ formatNumber(ghgValue, 0) }}</span><span v-else>-</span> kg/m<sup>2</sup></span></li>
          <li><span><span class="numbers" v-if="peValue">{{ formatNumber(peValue, 0) }}</span><span v-else>-</span> kWh/m<sup>2</sup></span></li>
        </ul>
        <ul class="building-attributes">
          <li v-if="usage && usage.name !== 'UNKNOWN'">{{ $t(`_buildingTags.usage.${usage.name}`) }}</li>
          <li v-else><span class="missing-data">{{ $t('_buildingTags.usage.UNKNOWN') }}</span></li>
          <li v-if="canton && canton.name !== 'UNKNOWN'">{{ canton.name }}</li>
          <li v-else><span class="missing-data">{{ $t('unknownCanton') }}</span></li>
          <li v-if="heating && heating.name !== 'UNKNOWN'">{{ $t(`_buildingTags.heating.${heating.name}`) }}</li>
          <li v-else><span class="missing-data">{{ $t('_buildingTags.heating.UNKNOWN') }}</span></li>
          <li v-if="decade && decade.name !== 'UNKNOWN'">{{ $t(`_buildingTags.decade.${decade.name}`) }}</li>
          <li v-else>{{ $t('_buildingTags.decade.UNKNOWN') }}</li>
        </ul>
      </footer>
    </router-link>
  </li>
</template>

<script>
import RingIndicator from '@/components/shared/RingIndicator'
import { VPopover } from 'v-tooltip'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    building: {
      type: Object,
      required: true
    },
    searchText: {
      type: String
    }
  },
  computed: {
    ghgValue () {
      return this.building.ghg / this.building.energy_area
    },
    peValue () {
      return this.building.pe / this.building.energy_area
    },
    heating () {
      return this.building.tags.find(t => t.gname === 'heating')
    },
    usage () {
      return this.building.tags.find(t => t.gname === 'usage')
    },
    canton () {
      return this.building.tags.find(t => t.gname === 'canton')
    },
    decade () {
      return this.building.tags.find(t => t.gname === 'decade')
    },
    wallsAge () {
      return (new Date().getFullYear()) - this.building.walls_year
    },
    windowsAge () {
      return (new Date().getFullYear()) - this.building.windows_year
    },
    basementAge () {
      return (new Date().getFullYear()) - this.building.basement_year
    },
    roofAge () {
      return (new Date().getFullYear()) - this.building.roof_year
    },
    roofMaxLifetime () {
      return this.building.roof_replacement_year + 5
    },
    roofMinLifetime () {
      return this.building.roof_replacement_year
    },
    heatingAge () {
      return (new Date().getFullYear()) - this.building.heating_year
    }
  },
  components: {
    RingIndicator,
    VPopover
  }
}
</script>

<style>
.portfolio-list-item {
  display: contents;
}

.portfolio-list-item .missing-data {
  color: var(--error-color);
}

.portfolio-list-item .unsure-data {
  color: var(--warning-color);
}

.portfolio-list-item .ring-indicator {
  height:12px;
  margin-right:2px;
}

.portfolio-list-item-lifecycle {
  display: grid;
  grid-template-columns: 32px 1fr;
}

.portfolio-list-item-lifecycle .ring-indicator {
  height:24px;
}

.portfolio-list-item .gis-errors {
  color:var(--warning-color);
  font-size: var(--font-xs);
}

.portfolio-list-item > a {
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.portfolio-list-item > a > section {
  display: contents;
}

.portfolio-list-item header,
.portfolio-list-item main,
.portfolio-list-item footer {
  padding: var(--box-padding);
}

.portfolio-list-item header {
  border-bottom: var(--box-border);
  display: grid;
  grid-template-columns: auto 70px;
}

.portfolio-list-item .building-id {
  font-size: var(--font-xs);
  font-weight: 400;
}

.portfolio-list-item main {
  display: grid;
  grid-template-columns: 1fr 50px;
  /* padding-bottom: var(--spacing-l); */
}

.portfolio-list-item .building-description {
  font-size: var(--font-s);
  font-weight: 600;
  overflow: hidden;
}

.portfolio-list-item footer {
  align-self: end;
  border-top: var(--box-border);
  font-weight: 400;
}

.portfolio-list-item footer ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: var(--font-xs);
}

.portfolio-list-item footer ul ~ ul{
  margin-top: var(--spacing-xs);
}

.portfolio-list-item footer .numbers {
  font-weight: 600;
}

/* portfolio table styles */
.portfolio-table .portfolio-list-item header,
.portfolio-table .portfolio-list-item main,
.portfolio-table .portfolio-list-item footer {
  padding: 0;
}

.portfolio-table .portfolio-list-item header {
  border-bottom: none;
}

.portfolio-table .portfolio-list-item main {
  padding: var(--box-padding-v) 0 0 0;
}

/* flatten out DOM of cards layout */
.portfolio-table .portfolio-list-item > a,
.portfolio-table .portfolio-list-item > a > section,
.portfolio-table .building-description,
.portfolio-table .portfolio-list-item header,
.portfolio-table .portfolio-list-item main,
.portfolio-table .portfolio-list-item footer,
.portfolio-table .portfolio-list-item footer ul {
  display: contents;
}

.portfolio-table .portfolio-list-item footer ul ~ ul{
  margin-top: 0;
}

/* formatting of table cells */
.portfolio-table header > div,
.portfolio-table .building-description div,
.portfolio-table .portfolio-list-item footer ul li {
  white-space: nowrap;
  overflow: hidden;
  padding: var(--box-padding-v) calc(var(--spacing-m) / 2);
  display: grid;
  align-content: center;
}

/* adding top border to each cell */
.portfolio-table .portfolio-list-item ~ .portfolio-list-item header > div,
.portfolio-table .portfolio-list-item ~ .portfolio-list-item .building-description div,
.portfolio-table .portfolio-list-item ~ .portfolio-list-item footer ul li {
  border-top: var(--box-border);
}

.portfolio-table .portfolio-list-item footer ul.building-kpis li {
  text-align: end;
}

.portfolio-table .portfolio-list-item footer ul.building-attributes li:last-child {
  padding-right: var(--box-padding-h);
}

</style>
