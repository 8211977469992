<i18n>
{
  "de": {
    "byAreaButton": "nach Fläche",
    "countButton": "nach Anzahl",
    "totalLabel": "Total",
    "buildingTypeTitle": "Übersicht",
    "bubbleTitle": "Bubble",
    "yearTitle": "Baujahr",
    "areaTitle": "Fläche",
    "usageTitle": "Hauptnutzung",
    "heatingTitle": "Heizung",
    "ghgS1Title": "GHG Scope 1",
    "ghgTitle": "GHG Scope 2",
    "byUsage": "Nach Verwendungszweck",
    "byCarrier": "Nach Energieträger",
    "peTitle": "Primärenergie",
    "eeTitle": "Endenergie",
    "heatTitle": "Wärme",
    "rhDemandHistogramTitle": "Nutzenergie Raumwärme",
    "heatEndEnergyHistogramTitle": "Endenergiebedarf Heizung inkl. WW",
    "rhCalibrationHistogramTitle": "Kalibrierung Raumwärme",
    "electricityTitle": "Strom",
    "elDemandHistogramTitle": "Strombedarf Total",
    "elGeneralHistogramTitle": "Allgemeinstrom",
    "elTenantHistogramTitle": "Mieterstrom",
    "availabilityTitle": "Machbarkeit",
    "ewsPieTitle": "Erdwärmesonden",
    "gwPieTitle": "Wärmenutzung Grundwasser",
    "districtPieTitle": "Wärmenetze",
    "componentsTitle": "Bauteile",
    "heatingYearTitle": "Baujahr Heizung",
    "wallsYearTitle": "Baujahr Fassade",
    "windowsYearTitle": "Baujahr Fenster",
    "roofYearTitle": "Baujahr Dach",
    "dataQualityTitle": "Daten"
  }
}
</i18n>

<template>
  <div class="portfolio-kpi-overview">
    <div class="grouping-type">
      <a href="#" @click.prevent="byArea = false" v-if="byArea">{{ $t('byAreaButton') }}</a>
      <a href="#" @click.prevent="byArea = true" v-if="!byArea">{{ $t('countButton') }}</a>
    </div>
    <tabs>
      <tab :title="$t('buildingTypeTitle')" v-if="portfolio.enabled_features.KPI">
        <h3>{{ $t('heatingTitle') }}</h3>
        <tag-pie-chart
          :by-area="byArea"
          key="heatingpiechart"
          :group-by-key="'heating'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        />
        <h3>{{ $t('usageTitle') }}</h3>
        <tag-pie-chart
          :by-area="byArea"
          key="usagepiechart"
          :group-by-key="'usage'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        />
        <h3>{{ $t('yearTitle') }}</h3>
        <histogram-chart
          :by-area="byArea"
          key="buildingyearhistogram"
          :group-by-key="'building_year'"
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
        <h3>{{ $t('areaTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalEnergyArea) }} m<sup>2</sup>)</h3>
        <histogram-chart
          :by-area="byArea"
          key="areahistogram"
          :group-by-key="'energy_area'"
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
      </tab>
      <tab :title="$t('ghgS1Title')" v-if="portfolio.enabled_features.KPI">
        <h3>{{ $t('ghgS1Title') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalGhgS1 / 1000, 0) }} t)</h3>
        <histogram-chart
          :by-area="byArea"
          key="ghgs1histogram"
          :group-by-key="'ghg_s1'"
          area-specific
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
        <h3>{{ $t('byCarrier') }}</h3>
        <kpi-pie-chart
          key="ghg_s1_by_carrier"
          :kpi-type="'ghg_s1'"
          :group-by-type="'carrier'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        />
        <h3>{{ $t('byUsage') }}</h3>
        <kpi-pie-chart
          key="ghg_s1_by_usage"
          :kpi-type="'ghg_s1'"
          :group-by-type="'usage'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        />
      </tab>
      <tab :title="$t('ghgTitle')" v-if="portfolio.enabled_features.KPI">
        <h3>{{ $t('ghgTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalGhg / 1000, 0) }} t)</h3>
        <histogram-chart
          :by-area="byArea"
          key="ghghistogram"
          :group-by-key="'ghg'"
          area-specific
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
        <h3>{{ $t('byCarrier') }}</h3>
        <kpi-pie-chart
          key="ghg_by_carrier"
          :kpi-type="'ghg'"
          :group-by-type="'carrier'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        />
        <h3>{{ $t('byUsage') }}</h3>
        <kpi-pie-chart
          key="ghg_by_usage"
          :kpi-type="'ghg'"
          :group-by-type="'usage'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        />
      </tab>
      <tab :title="$t('peTitle')" v-if="portfolio.enabled_features.KPI">
        <h3>{{ $t('peTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalPe / 1000, 0) }} MWh)</h3>
        <histogram-chart
          :by-area="byArea"
          key="pehistogram"
          :group-by-key="'pe'"
          area-specific
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
        <h3>{{ $t('byCarrier') }}</h3>
        <kpi-pie-chart
          key="pe_by_carrier"
          :kpi-type="'pe'"
          :group-by-type="'carrier'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        />
        <h3>{{ $t('byUsage') }}</h3>
        <kpi-pie-chart
          key="pe_by_usage"
          :kpi-type="'pe'"
          :group-by-type="'usage'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        />
      </tab>
      <tab :title="$t('eeTitle')" v-if="portfolio.enabled_features.KPI">
        <h3>{{ $t('eeTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalEe / 1000, 0) }} MWh)</h3>
        <histogram-chart
          :by-area="byArea"
          key="eehistogram"
          :group-by-key="'ee'"
          area-specific
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
        <h3>{{ $t('byCarrier') }}</h3>
        <kpi-pie-chart
          key="ee_by_carrier"
          :kpi-type="'ee'"
          :group-by-type="'carrier'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        />
        <h3>{{ $t('byUsage') }}</h3>
        <kpi-pie-chart
          key="ee_by_usage"
          :kpi-type="'ee'"
          :group-by-type="'usage'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        />
      </tab>
      <tab :title="$t('heatTitle')" v-if="portfolio.enabled_features.KPI">
        <h3>{{ $t('rhDemandHistogramTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalRh / 1000, 0) }} MWh)</h3>
        <histogram-chart
          :by-area="byArea"
          key="rhhistogram"
          :group-by-key="'rh_demand'"
          area-specific
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
        <h3>{{ $t('heatEndEnergyHistogramTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalHeatEndEnergy / 1000, 0) }} MWh)</h3>
        <histogram-chart
          :by-area="byArea"
          key="heat_end_energy_histogram"
          :group-by-key="'heat_end_energy'"
          area-specific
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
        <h3>{{ $t('rhCalibrationHistogramTitle') }}</h3>
        <histogram-chart
          :by-area="byArea"
          key="rhcalibhistogram"
          :group-by-key="'rh_calibration'"
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
      </tab>
      <tab :title="$t('electricityTitle')" v-if="portfolio.enabled_features.KPI">
        <h3>{{ $t('elDemandHistogramTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalEl / 1000, 0) }} MWh)</h3>
        <histogram-chart
          :by-area="byArea"
          key="elhistogram"
          :group-by-key="'el_demand'"
          area-specific
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
        <h3>{{ $t('elTenantHistogramTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalElTenant / 1000, 0) }} MWh)</h3>
        <histogram-chart
          :by-area="byArea"
          key="eltenanthistogram"
          :group-by-key="'el_tenant'"
          area-specific
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
        <h3>{{ $t('elGeneralHistogramTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalElGeneral / 1000, 0) }} MWh)</h3>
        <histogram-chart
          :by-area="byArea"
          key="elgeneralhistogram"
          :group-by-key="'el_general'"
          area-specific
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
      </tab>
      <tab :title="$t('componentsTitle')" v-if="portfolio.enabled_features.KPI">
        <h3>{{ $t('heatingYearTitle') }}</h3>
        <histogram-chart
          :by-area="byArea"
          key="heatingyearhistogram"
          :group-by-key="'heating_year'"
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
        <h3>{{ $t('wallsYearTitle') }}</h3>
        <histogram-chart
          :by-area="byArea"
          key="wallsyearhistogram"
          :group-by-key="'walls_year'"
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
        <h3>{{ $t('windowsYearTitle') }}</h3>
        <histogram-chart
          :by-area="byArea"
          key="windowsyearhistogram"
          :group-by-key="'windows_year'"
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
        <h3>{{ $t('roofYearTitle') }}</h3>
        <histogram-chart
          :by-area="byArea"
          key="roofyearhistogram"
          :group-by-key="'roof_year'"
          :buildings="portfolio.buildings"
          class="histogram-chart"
          exportable
        />
      </tab>
      <tab :title="$t('availabilityTitle')" v-if="portfolio.enabled_features.KPI">
        <h3>{{ $t('ewsPieTitle') }}</h3>
        <tag-pie-chart
          :by-area="byArea"
          key="ewspiechart"
          :group-by-key="'feasibility_ews'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        />
        <!-- <h3>{{ $t('gwPieTitle') }}</h3>
        <tag-pie-chart
          :by-area="byArea"
          key="gwpiechart"
          :group-by-key="'feasibility_gw'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        /> -->
        <h3>{{ $t('districtPieTitle') }}</h3>
        <tag-pie-chart
          :by-area="byArea"
          key="districtpiechart"
          :group-by-key="'availability_district'"
          :buildings="portfolio.buildings"
          class="pie-chart"
        />
      </tab>
      <tab :title="$t('dataQualityTitle')">
        <data-quality-chart
          :by-area="byArea"
          key="dataqualitychart"
          :buildings="portfolio.buildings"
          class="data-quality-chart"
        />
      </tab>
      <tab :title="$t('bubbleTitle')">
        <bubble-chart-wrapper
          key="buildingbubble"
          :buildings="portfolio.buildings"
          class="bubble-chart"
          @click="onBubbleClick"
        />
      </tab>
    </tabs>
  </div>
</template>

<script>
import Tabs from '@/components/shared/Tabs'
import Tab from '@/components/shared/Tab'

import BubbleChartWrapper from '@/components/portfolio/kpi/charts/BubbleChartWrapper'

import DataQualityChart from '@/components/portfolio/kpi/charts/DataQualityChart'
import TagPieChart from '@/components/portfolio/kpi/charts/TagPieChart'
import KpiPieChart from '@/components/portfolio/kpi/charts/KpiPieChart'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart'
export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      'byArea': false
    }
  },
  computed: {
    totalEnergyArea () {
      return this.portfolio.buildings.reduce((pv, cv) => pv + cv.energy_area, 0)
    },
    totalGhgS1 () {
      return this.portfolio.buildings.reduce((pv, cv) => pv + cv.ghg_s1, 0)
    },
    totalGhg () {
      return this.portfolio.buildings.reduce((pv, cv) => pv + cv.ghg, 0)
    },
    totalPe () {
      return this.portfolio.buildings.reduce((pv, cv) => pv + cv.pe, 0)
    },
    totalEe () {
      return this.portfolio.buildings.reduce((pv, cv) => pv + cv.ee, 0)
    },
    totalRh () {
      return this.portfolio.buildings.reduce((pv, cv) => pv + cv.rh_demand, 0)
    },
    totalHeatEndEnergy () {
      return this.portfolio.buildings.reduce((pv, cv) => pv + cv.heat_end_energy, 0)
    },
    totalEl () {
      return this.portfolio.buildings.reduce((pv, cv) => pv + cv.el_demand, 0)
    },
    totalElTenant () {
      return this.portfolio.buildings.reduce((pv, cv) => pv + cv.el_tenant, 0)
    },
    totalElGeneral () {
      return this.portfolio.buildings.reduce((pv, cv) => pv + cv.el_general, 0)
    }
  },
  methods: {
    onBubbleClick (building) {
      this.$router.push({ name: 'building', params: { portfolio_id: this.portfolio.id, building_id: building.id } })
    }
  },
  components: {
    TagPieChart,
    KpiPieChart,
    DataQualityChart,
    HistogramChart,
    BubbleChartWrapper,
    Tab,
    Tabs
  }
}
</script>
<style>
.portfolio-kpi-overview {
  padding: var(--spacing-s);
}
.portfolio-kpi-overview .grouping-type {
  padding: var(--spacing-s);
  font-style: italic;
}
.portfolio-kpi-overview .histogram-chart > div {
  height: 200px;
}
.portfolio-kpi-overview .data-quality-chart > div {
  height: 300px;
}
.portfolio-kpi-overview .pie-chart > div {
  height: 200px;
}
.portfolio-kpi-overview h3 {
  text-align: center;
  margin-top: var(--spacing-m);
}
.portfolio-kpi-overview .bubble-chart {
  height:250px;
}
</style>
