<i18n>
{
  "de": {
    "groupByTypes": {
      "carrier": "Energieträger",
      "usage": "Verwendungszweck"
    },
    "kpiTypes": {
      "ghg_s1": "GHG-Emissionen Scope 1 (t)",
      "ghg": "GHG-Emissionen Scope 2 (t)",
      "pe": "Primärenergie (MWh)",
      "ee": "Endenergie (MWh)"
    },
    "units": {
      "ghg_s1": "t",
      "ghg": "t",
      "pe": "MWh",
      "ee": "MWh"
    }
  }
}
</i18n>

<template>
  <exportable-pie-chart :chart-data="chartData" :options="options" :grouping-name="$t(`groupByTypes.${groupByType}`)" class="kpi-pie-chart" />
</template>

<script>
import ExportablePieChart from '@/components/shared/charts/ExportablePieChart'
import colorPalettes from '@/services/color-palettes'

export default {
  props: {
    buildings: {
      type: Array,
      required: true
    },
    kpiType: {
      type: String, // ghg, ghg_s1 or pe
      required: true
    },
    groupByType: { // carrier or usage
      type: String,
      required: true
    }
  },
  components: {
    ExportablePieChart
  },
  computed: {
    groupedValues () {
      const self = this
      return Object.values(this.buildings.reduce(function (res, b) {
        const group = b[`${self.kpiType}_by_${self.groupByType}`]
        if (group !== null) {
          for (const [groupKey, groupValue] of Object.entries(group)) {
            if (!res[groupKey]) {
              res[groupKey] = { key: groupKey, result: 0 }
            }
            res[groupKey].result += groupValue / 1000 // Convert kg to t and kWh to MWh
          }
        }
        return res
      }, {}))
    },
    labels () {
      if (this.groupByType === 'usage') {
        return this.groupedValues.map(g => this.$t(`_energyUsages.${g.key}`))
      } else if (this.groupByType === 'carrier') {
        return this.groupedValues.map(g => this.$t(`_energyCarriers.${g.key}`))
      } else {
        return undefined
      }
    },
    units () {
      return this.$t(`units.${this.kpiType}`)
    },
    colors () {
      if (this.groupByType === 'usage') {
        return this.groupedValues.map(g => colorPalettes.usageColors[g.key])
      } else if (this.groupByType === 'carrier') {
        return this.groupedValues.map(g => colorPalettes.carrierColors[g.key])
      } else {
        return undefined
      }
    },
    options () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              return `${data.labels[tooltipItem.index]}: ${this.formatNumber(value, 1)} ${this.units}`
            }
          }
        },
        legend: {
          position: 'bottom',
          display: true
        }
      }
    },
    chartData () {
      if (this.buildings === undefined) {
        return {}
      }
      return {
        datasets: [{
          label: this.$t(`kpiTypes.${this.kpiType}`),
          data: this.groupedValues.map(g => g.result),
          hoverOffset: 4,
          backgroundColor: this.colors
        }],
        labels: this.labels
      }
    }
  }
}
</script>
