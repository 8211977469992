<!--
Dropdown filtering menu to select a number of Tags in one TagGroup. When dropdown is closed, it shows which Tags are selected
-->
<i18n>
{
  "de": {
    "removeAll": "Filter entfernen",
    "unassignedBuildings": "Nicht zugewiesene Gebäude"
  }
}
</i18n>

<template>
  <dropdown-menu left stayOpen class="button tag-group-dropdown">
    <template v-slot:title>
      <!-- Displayed when dropdown is closed -->
      <span v-if="tagGroup.auto">{{ $t(`_tagGroupNames.${tagGroup.name}`) }}</span>
      <span v-else>{{ tagGroup.name }}</span>
      <div class="active-filters" v-for="tag in selectedTags" :key="tag.id">
        <span v-if="tagGroup.auto">{{ $t(`_buildingTags.${tagGroup.name}.${tag.name}`) }}</span>
        <span v-else>{{ tag.name }}</span>
      </div>
      <div class="active-filters" v-if="unassignedSelected">
        {{ $t('unassignedBuildings' )}}
      </div>
      <a href="#" @click.prevent="removeAll" v-if="selectedTagIds.length">{{ $t('removeAll') }}</a>
    </template>
    <template #contents>
      <!-- Dropdown menu items -->
      <li v-for="(tagStats, tagIdx) in allTags" :key="tagIdx" v-show="tagStats.buildingCount > 0">
        <input type="checkbox" v-model="selectedTagIds" :id="tagStats.tag.id" @change="onTagSelect" :value="tagStats.tag.id">
        <label :for="tagStats.tag.id" v-if="tagGroup.auto">{{ $t(`_buildingTags.${tagGroup.name}.${tagStats.tag.name}`) }} ({{ tagStats.buildingCount }})</label>
        <label :for="tagStats.tag.id" v-else>{{ tagStats.tag.name }} ({{ tagStats.buildingCount }})</label>
      </li>
      <li v-if="portfolio.tag_counts && portfolio.tag_counts[tagGroup.id][0] > 0">
        <input type="checkbox" v-model="selectedTagIds" :id="'unassigned'" @change="onTagSelect" :value="0">
        <label :for="'unassigned'">{{ $t('unassignedBuildings') }} ({{ portfolio.tag_counts[tagGroup.id][0] }})</label>
      </li>
    </template>
  </dropdown-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DropdownMenu from '@/components/shared/DropdownMenu'

export default {
  props: {
    tagGroup: {
      type: Object,
      required: true
    },
    portfolio: {
      type: Object
    }
  },
  data () {
    return {
      selectedTagIds: []
    }
  },
  watch: {
    query () {
      this.updateFilters()
    }
  },
  mounted () {
    this.updateFilters()
  },
  computed: {
    ...mapGetters({
      query: 'portfolio/query'
    }),
    selectedTags () {
      return this.selectedTagIds.filter(tagId => tagId !== 0).map(tagId => this.tagGroup.tags.find(t => t.id === tagId))
    },
    unassignedSelected () {
      return this.selectedTagIds.filter(tagId => tagId === 0).length > 0
    },
    allTags () {
      return this.portfolio.tag_counts && this.tagGroup.tags.map(t => {
        return {
          tag: t,
          buildingCount: this.portfolio.tag_counts[this.tagGroup.id][t.id]
        }
      })
    }
  },
  methods: {
    ...mapActions({
      setTagFilters: 'portfolio/setTagFilters'
    }),
    onTagSelect () {
      this.setTagFilters({
        payload: {
          tagGroupId: this.tagGroup.id,
          tagIdFilters: [...this.selectedTagIds]
        },
        portfolioId: this.portfolio.id
      })
    },
    updateFilters () {
      this.selectedTagIds = []
      if (this.query.filters[this.tagGroup.id] !== undefined) {
        this.query.filters[this.tagGroup.id].forEach(value => {
          this.selectedTagIds.push(value)
        })
      }
    },
    removeAll () {
      this.selectedTagIds = []
      this.onTagSelect()
    }
  },
  components: {
    DropdownMenu
  }
}
</script>

<style>
.tag-group-dropdown li {
  padding: var(--box-padding);
}

.tag-group-dropdown .active-filters {
  font-size: var(--font-xs);
  font-weight: 600;
}

.tag-group-dropdown .remove-all-icon {
  opacity: 0.3;
  display: inline-block;
}

.tag-group-dropdown .remove-all-icon:hover {
  opacity: 1.0;
}

.tag-group-dropdown {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  text-align: left;
}
</style>
