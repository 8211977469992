<i18n>
{
  "de": {
    "nFound": "Liegenschaften gefunden",
    "noBuildingsFound": "Dieses Portfolio ist noch leer",
    "addBuildingButton": "Neue Liegenschaft hinzufügen"
  }
}
</i18n>

<template>
  <div class="portfolio-list">
    <div class="portfolio-controls">
      <div class="buildings-count" v-if="portfolio.buildings.length > 0">{{ portfolio.buildings.length }} {{ $t('nFound') }}</div>
      <div v-if="portfolio.buildings.length > 0">
        <sort-dropdown :portfolio="portfolio" />
        <sort-reverse :portfolio="portfolio" />
        <button @click.prevent="exportPortfolio" class="button"><img class="icon" src="/icons/download.svg"></button>
        <router-link class="button" v-if="portfolio.permissions && portfolio.permissions.EDIT_BUILDINGS" :to="{ name: 'addBuilding', params: { portfolio_id: portfolio.id }}"><img class="icon" src="/icons/plus.svg"></router-link>
      </div>
    </div>
    <ul :class="classes" v-if="portfolio.buildings.length > 0">
      <portfolio-list-item v-for="building in portfolio.buildings"
        :key="building.id"
        :portfolio="portfolio"
        :building="building"
        :search-text="searchText" />
    </ul>
    <div v-if="portfolio.buildings_count === 0">
      <div>{{ $t('noBuildingsFound') }}</div>
    </div>
    <button-wrapper>
      <router-link class="button" v-if="portfolio.permissions && portfolio.permissions.EDIT_BUILDINGS" :to="{ name: 'addBuilding', params: { portfolio_id: portfolio.id }}">{{ $t('addBuildingButton') }}</router-link>
    </button-wrapper>
  </div>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import PortfolioListItem from './PortfolioListItem'
import SortDropdown from './SortDropdown'
import SortReverse from './SortReverse'

import exportService from '@/services/export-service'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    searchText: {
      type: String,
      required: false
    },
    asTable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      return {
        'portfolio-table': this.asTable
      }
    }
  },
  methods: {
    exportPortfolio () {
      let data = [exportService.buildingCsvHeader(this.portfolio.scenarios), ...this.portfolio.buildings.map(b => exportService.buildingToCsv(b))]
      exportService.saveCsv(data.join('\n'), 'export.csv')
    }
  },
  components: {
    ButtonWrapper,
    PortfolioListItem,
    SortDropdown,
    SortReverse
  }
}
</script>

<style>
.portfolio-list .portfolio-controls {
  display: grid;
  grid-template-columns: auto max-content;
  padding-bottom: var(--spacing-s);
  align-items:center;
}

.portfolio-list > ul {
  display: grid;
  grid-gap: var(--spacing-m);
  column-gap: var(--spacing-m);
  row-gap: var(--spacing-l);
}

.portfolio-list > ul > li > a {
  border-radius: var(--box-radius);
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
}

.portfolio-list > ul > li > a:hover {
  box-shadow: 0px 0px 4px 2px var(--shadow-color);
}

.portfolio-list ul.portfolio-table {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: 1fr 3fr 2fr 1fr 1fr 1.25fr 1fr 0.5fr 0.5fr 1fr 90px;
  grid-template-rows: none;
  grid-gap: 0;
  column-gap: 0;
  row-gap: 0;
  border: var(--box-border);
  border-radius: var(--box-radius);
}

.portfolio-list ul.portfolio-table .gis-errors {
  display: none;
}

.portfolio-list ul.portfolio-table .v-popover {
  grid-column: 11;
}

@media screen and (min-width: 18.4rem) {
  .portfolio-list > ul {
    grid-column: 1 / -1;
    grid-template-columns: var(--grid-template-m);
  }
}

@media screen and (min-width: 52.9rem) {
  .portfolio-list > ul {
    grid-column: 1 / -3;
    align-self: start;
    display: grid;
    grid-template-columns: var(--grid-template-m);

  }
}

@media screen and (min-width: 87.4rem) {
  .portfolio-list > ul {
    grid-column-end: -3;
  }
}

@media screen and (min-width: 139.15rem) {
  .portfolio-list > ul {
    grid-column-end: -4;
  }
}
</style>
