<i18n>
{
  "de": {
    "yScale": {
      "COUNT": "Anzahl Liegenschaften",
      "energy_area": "EBF (m\u00B2)"
    },
    "xScale": {
      "energy_area": "m\u00B2 (EBF)",
      "ghg": "kg/m\u00B2",
      "ghg_s1": "kg/m\u00B2",
      "pe": "kWh/m\u00B2",
      "ee": "kWh/m\u00B2",
      "el_production": "kWh/m\u00B2",
      "rh_demand": "kWh/m\u00B2",
      "heat_end_energy": "kWh/m\u00B2",
      "el_demand": "kWh/m\u00B2",
      "el_general": "kWh/m\u00B2",
      "el_tenant": "kWh/m\u00B2",
      "building_year": "Baujahr",
      "heating_year": "Baujahr Heizung",
      "walls_year": "Baujahr Fassade",
      "windows_year": "Baujahr Fenster",
      "roof_year": "Baujahr Dach",
      "rh_calibration": "%"
    }
  }
}
</i18n>

<template>
  <exportable-bar-chart :chart-data="chartData" :options="options" :class="classes" v-if="exportable" />
  <bar-chart :chart-data="chartData" :options="options" :class="classes" v-else />
</template>

<script>
import BarChart from '@/components/shared/charts/BarChart'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'
// import colorPalettes from '@/services/color-palettes'

const binConfig = {
  'building_year': {
    'min-interval': 1900,
    'max-interval': 2020,
    'step-size': 10,
    'format-year': true
  },
  'energy_area': {
    'min-interval': 1000,
    'max-interval': 10000,
    'step-size': 1000
  },
  'ghg_s1': {
    'min-interval': 5,
    'max-interval': 35,
    'step-size': 5,
    'gradient': 'regular'
  },
  'ghg': {
    'min-interval': 5,
    'max-interval': 35,
    'step-size': 5,
    'gradient': 'regular'
  },
  'pe': {
    'min-interval': 50,
    'max-interval': 350,
    'step-size': 50,
    'gradient': 'regular'
  },
  'ee': {
    'min-interval': 25,
    'max-interval': 175,
    'step-size': 25,
    'gradient': 'regular'
  },
  'rh_demand': {
    'min-interval': 20,
    'max-interval': 140,
    'step-size': 20,
    'gradient': 'regular'
  },
  'heat_end_energy': {
    'min-interval': 25,
    'max-interval': 200,
    'step-size': 25,
    'gradient': 'regular'
  },
  'rh_calibration': {
    'min-interval': 30,
    'max-interval': 190,
    'step-size': 20
  },
  'el_demand': {
    'min-interval': 10,
    'max-interval': 95,
    'step-size': 15,
    'gradient': 'regular'
  },
  'el_tenant': {
    'min-interval': 10,
    'max-interval': 95,
    'step-size': 15,
    'gradient': 'regular'
  },
  'el_general': {
    'min-interval': 2,
    'max-interval': 14,
    'step-size': 2,
    'gradient': 'regular'
  },
  'el_production': {
    'min-interval': 5,
    'max-interval': 25,
    'step-size': 5,
    'gradient': 'reverse'
  },
  'heating_year': {
    'min-interval': 1970,
    'max-interval': 2020,
    'step-size': 5,
    'format-year': true
  },
  'walls_year': {
    'min-interval': 1970,
    'max-interval': 2020,
    'step-size': 5,
    'format-year': true
  },
  'windows_year': {
    'min-interval': 1970,
    'max-interval': 2020,
    'step-size': 5,
    'format-year': true
  },
  'roof_year': {
    'min-interval': 1970,
    'max-interval': 2020,
    'step-size': 5,
    'format-year': true
  }
}

export default {
  props: {
    buildings: {
      type: Array,
      required: true
    },
    groupByKey: {
      type: String,
      required: true
    },
    areaSpecific: {
      type: Boolean,
      default: false
    },
    byArea: {
      type: Boolean,
      default: false
    },
    miniChart: {
      type: Boolean,
      default: false
    },
    highlightValue: {
      type: Number
    },
    exportable: {
      type: Boolean
    }
  },
  components: {
    BarChart,
    ExportableBarChart
  },
  computed: {
    classes () {
      return {
        'histogram-chart': true,
        'mini': this.miniChart
      }
    },
    groupedValues () {
      const self = this
      const groupedValues = this.buildings.reduce(function (res, value) {
        let keyVal
        if (self.areaSpecific) {
          keyVal = value[self.groupByKey] / value.energy_area
        } else if (self.groupByKey === 'rh_calibration') {
          // Workaround
          keyVal = value[self.groupByKey] * 100
        } else {
          keyVal = value[self.groupByKey]
        }
        // Below min: Idx = -1
        // Above min: Idx = (max - min) / stepSize
        const keyValIdx = Math.floor(Math.max(-1, Math.min((self.maxInterval - self.minInterval) / self.intervalStepSize, (keyVal - self.minInterval) / self.intervalStepSize)))
        if (!res[keyValIdx]) {
          res[keyValIdx] = { key: keyValIdx, result: 0 }
        }
        if (self.byArea) {
          res[keyValIdx].result += value['energy_area']
        } else {
          res[keyValIdx].result += 1
        }
        return res
      }, {})
      return this.valueIndices.map(i => groupedValues[i] === undefined ? 0 : groupedValues[i])
    },
    valueIndices () {
      return [-1, ...Array(Math.ceil((this.maxInterval - this.minInterval) / this.intervalStepSize + 1)).keys()]
    },
    minInterval () {
      return binConfig[this.groupByKey]['min-interval']
    },
    maxInterval () {
      return binConfig[this.groupByKey]['max-interval']
    },
    intervalStepSize () {
      return binConfig[this.groupByKey]['step-size']
    },
    labels () {
      return this.valueIndices.map(v => {
        const rangeFrom = this.minInterval + v * this.intervalStepSize
        const rangeTo = this.minInterval + (v + 1) * this.intervalStepSize
        if (binConfig[this.groupByKey]['format-year']) {
          if (rangeFrom < this.minInterval) {
            return `< ${rangeTo}`
          } else if (rangeTo > this.maxInterval) {
            return `> ${rangeFrom}`
          } else {
            return `${rangeFrom} - ${rangeTo - 1}`
          }
        } else {
          const rangeFromF = this.formatNumber(rangeFrom)
          const rangeToF = this.formatNumber(rangeTo)
          if (rangeFrom < this.minInterval) {
            return `< ${rangeToF}`
          } else if (rangeTo > this.maxInterval) {
            return `> ${rangeFromF}`
          } else {
            return `${rangeFromF} - ${rangeToF}`
          }
        }
      })
    },
    yScaleLabel () {
      if (this.byArea) {
        return this.$t(`yScale.energy_area`)
      } else {
        return this.$t(`yScale.COUNT`)
      }
    },
    xScaleLabel () {
      return this.$t(`xScale.${this.groupByKey}`)
    },
    options () {
      const self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        layout: {
          padding: {
            bottom: this.miniChart ? 0 : 30 // workaround to ensure tooltip has enough space
          }
        },
        tooltips: {
          enabled: !this.miniChart
        },
        scales: {
          xAxes: [{
            display: !this.miniChart,
            scaleLabel: {
              display: !binConfig[this.groupByKey]['format-year'],
              labelString: this.xScaleLabel
            }
          }],
          yAxes: [{
            display: !this.miniChart,
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return self.formatNumber(value)
              }
            },
            scaleLabel: {
              labelString: this.yScaleLabel
            }
          }]
        }
      }
    },
    colors () {
      // https://stackoverflow.com/questions/17525215/calculate-color-values-from-green-to-red
      function percentageToHsl (percentage, hue0, hue1, l) {
        const hue = (percentage * (hue1 - hue0)) + hue0
        return 'hsl(' + hue + ', 80%, ' + l + '%)'
      }
      let keyValIdx
      if (this.highlightValue !== undefined) {
        keyValIdx = Math.floor(Math.max(-1, Math.min((this.maxInterval - this.minInterval) / this.intervalStepSize, (this.highlightValue - this.minInterval) / this.intervalStepSize)))
      }
      return this.valueIndices.map(v => {
        const highlighted = keyValIdx === undefined || keyValIdx === v
        if (binConfig[this.groupByKey]['gradient'] === 'regular') {
          if (highlighted) {
            return percentageToHsl((v + 1) / ((this.maxInterval - this.minInterval) / this.intervalStepSize), 120, 0, 50)
          } else {
            return 'hsl(202, 0%, 90%)'
          }
        } else if (binConfig[this.groupByKey]['gradient'] === 'reverse') {
          if (highlighted) {
            return percentageToHsl(1 - (v + 1) / ((this.maxInterval - this.minInterval) / this.intervalStepSize), 120, 0, 50)
          } else {
            return 'hsl(202, 0%, 90%)'
          }
        } else {
          return highlighted ? 'hsl(202, 92%, 14%)' : 'hsl(202, 92%, 90%)'
        }
      })
    },
    chartData () {
      if (this.buildings === undefined) {
        return {}
      }
      return {
        datasets: [{
          label: this.yScaleLabel,
          data: this.groupedValues.map(g => g.result),
          backgroundColor: this.colors
        }],
        labels: this.labels
      }
    }
  }
}
</script>
<style>
.histogram-chart.mini {
  height: 35px;
  width: 50px;
  display: inline-block;
  padding-bottom: 1px;
  border-bottom: 2px solid #eee;
}
</style>
