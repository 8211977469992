<i18n>
{
  "de": {
    "buildingsCount": "Anzahl Liegenschaften",
    "ebfSum": "Energiebezugsfläche",
    "xUnit": "Liegenschaften",
    "xUnitEbf": "m2",
    "qualityKeys": {
      "location": "Standort",
      "heating_type": "Heizungstyp",
      "heating_year": "Baujahr Heizung",
      "renovation_year": "Renovation",
      "energy_area": "EBF",
      "usage": "Nutzung"
    },
    "dataKeys": {
      "FULL": "Vollständig",
      "ESTIMATE": "Schätzung",
      "GIS_ESTIMATE": "Schätzung mit Geodaten",
      "MISSING": "Fehlend"
    }
  }
}
</i18n>

<template>
  <exportable-bar-chart :chart-data="chartData" :options="options" />
</template>

<script>
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'
import colorPalettes from '@/services/color-palettes'

const dataKeys = ['FULL', 'ESTIMATE', 'GIS_ESTIMATE', 'MISSING']
const propertyKeys = ['location', 'heating_type', 'heating_year', 'renovation_year', 'energy_area', 'usage']

export default {
  props: {
    buildings: {
      type: Array,
      required: true
    },
    byArea: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ExportableBarChart
  },
  computed: {
    groupedValues () {
      const self = this
      let result = {}
      for (const k in dataKeys) {
        result[dataKeys[k]] = {}
        for (const j in propertyKeys) {
          result[dataKeys[k]][propertyKeys[j]] = 0
        }
      }

      return this.buildings.reduce(function (res, building) {
        Object.keys(building.data_quality).forEach(propertyKey => {
          const qualityKey = building.data_quality[propertyKey]
          if (res[qualityKey] !== undefined && res[qualityKey][propertyKey] !== undefined) {
            if (self.byArea) {
              res[qualityKey][propertyKey] += building['energy_area']
            } else {
              res[qualityKey][propertyKey] += 1
            }
          }
        })
        return res
      }, result)
    },
    labels () {
      return propertyKeys.map(k => this.$t(`qualityKeys.${k}`))
    },
    options () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          display: true
        },
        scales: {
          xAxes: [{
            stacked: true
          }]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              const unit = this.byArea ? this.$t('xUnitEbf') : this.$t('xUnit')
              return `${data.labels[tooltipItem.index]}: ${this.formatNumber(value, 0)} ${unit}`
            }
          }
        }
      }
    },
    chartData () {
      if (this.buildings === undefined) {
        return {}
      }
      let datasets = []
      // console.log(this.groupedValues)
      for (const c in dataKeys) {
        datasets.push({
          label: this.$t(`dataKeys.${dataKeys[c]}`),
          data: Object.values(this.groupedValues[dataKeys[c]]),
          backgroundColor: colorPalettes.dataQualityColors[dataKeys[c]],
          stack: 'default'
        })
      }
      return {
        datasets: datasets,
        labels: this.labels
      }
    }
  }
}
</script>
