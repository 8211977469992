<i18n>
{
  "de": {
    "buildingsCount": "Anzahl Liegenschaften",
    "ebfSum": "Energiebezugsfläche",
    "xUnit": "Liegenschaften",
    "xUnitEbf": "m2",
    "groupByKeys": {
      "usage": "Hauptnutzung",
      "heating": "Heizungstyp",
      "feasibility_ews": "Zulässigkeit Erdwärmesonden",
      "feasibility_gw": "Zulässigkeit Grundwasser-Wärmenutzung",
      "availability_district": "Verfügbarkeit Fernwärme"
    }
  }
}
</i18n>

<template>
  <exportable-pie-chart :chart-data="chartData" :options="options" :grouping-name="$t(`groupByKeys.${groupByKey}`)" class="tag-pie-chart" />
</template>

<script>
import ExportablePieChart from '@/components/shared/charts/ExportablePieChart'
import colorPalettes from '@/services/color-palettes'

export default {
  props: {
    buildings: {
      type: Array,
      required: true
    },
    groupByKey: {
      type: String,
      required: true
    },
    byArea: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ExportablePieChart
  },
  computed: {
    groupedValues () {
      const self = this
      return Object.values(this.buildings.reduce(function (res, value) {
        const tag = value.tags.find(t => t.gname === self.groupByKey)
        if (tag === undefined) {
          return res
        }
        const keyVal = tag.name
        if (!res[keyVal]) {
          res[keyVal] = { key: keyVal, result: 0 }
        }
        if (self.byArea) {
          res[keyVal].result += value['energy_area']
        } else {
          res[keyVal].result += 1
        }
        return res
      }, {}))
    },
    labels () {
      return this.groupedValues && this.groupedValues.map(g => this.$t(`_buildingTags.${this.groupByKey}.${g.key}`))
    },
    colors () {
      if (this.groupByKey === 'usage') {
        return this.groupedValues && this.groupedValues.map(g => colorPalettes.buildingUsageColors[g.key])
      } else if (this.groupByKey === 'heating') {
        return this.groupedValues && this.groupedValues.map(g => colorPalettes.heatingColors[g.key])
      } else if (this.groupByKey === 'feasibility_ews') {
        return this.groupedValues && this.groupedValues.map(g => colorPalettes.ewsFeasibilityColors[g.key])
      } else if (this.groupByKey === 'feasibility_gw') {
        return this.groupedValues && this.groupedValues.map(g => colorPalettes.gwFeasibilityColors[g.key])
      } else if (this.groupByKey === 'availability_district') {
        return this.groupedValues && this.groupedValues.map(g => colorPalettes.districtAvailabilityColors[g.key])
      } else {
        return undefined
      }
    },
    options () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          display: true
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              const unit = this.byArea ? this.$t('xUnitEbf') : this.$t('xUnit')
              return `${data.labels[tooltipItem.index]}: ${this.formatNumber(value, 0)} ${unit}`
            }
          }
        }
      }
    },
    chartData () {
      if (this.buildings === undefined) {
        return {}
      }
      return {
        datasets: [{
          label: this.byArea ? this.$t('ebfSum') : this.$t('buildingsCount'),
          data: this.groupedValues && this.groupedValues.map(g => g.result),
          hoverOffset: 4,
          backgroundColor: this.colors
        }],
        labels: this.labels
      }
    }
  }
}
</script>
