<i18n>
{
  "de": {
    "yLabel": "GHG -Emissionen (kg/m\u00B2)",
    "xLabel": "Primärenergie (kWh/m\u00B2)",
    "emissionsTooltip": "GHG -Emissionen",
    "emissionsUnitsTooltip": "kg/m\u00B2",
    "energyTooltip": "Primärenergie",
    "energyUnitsTooltip": "kWh/m\u00B2"
  }
}
</i18n>

<template>
  <bubble-chart :chart-data="chartData" :options="options" class="bubble-chart" />
</template>

<script>
import BubbleChart from '@/components/shared/charts/BubbleChart'

export default {
  props: {
    buildings: {
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      default: true
    },
    highlightBuildingId: {
      type: Number,
      required: false
    }
  },
  components: {
    BubbleChart
  },
  computed: {
    buildingsWithData () {
      return this.buildings.filter(b => b.energy_area && b.pe !== null && b.ghg !== null)
    },
    options () {
      const self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (evt, array) => {
          if (array.length !== 0) {
            this.$emit('click', this.buildingsWithData[array[0]._index])
          }
        },
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('xLabel')
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('yLabel')
            }
          }]
        },
        tooltips: {
          displayColors: false,
          callbacks: {
            title: (tooltipItem, data) => `${this.buildingsWithData[tooltipItem[0].index].identifier ? this.buildingsWithData[tooltipItem[0].index].identifier : ''}`,
            beforeBody: (tooltipItem, data) => `${this.buildingsWithData[tooltipItem[0].index].description}, ${this.buildingsWithData[tooltipItem[0].index].municipality}`,
            beforeLabel: (tooltipItem, data) => `${this.$t('energyTooltip')}: ${self.formatNumber(tooltipItem.xLabel, 0)} ${this.$t('energyUnitsTooltip')}`,
            label: (tooltipItem, data) => `${this.$t('emissionsTooltip')}: ${self.formatNumber(tooltipItem.yLabel, 0)} ${this.$t('emissionsUnitsTooltip')}`
          }
        },
        legend: {
          display: false
        }
      }
    },
    chartData () {
      if (this.buildings === undefined) {
        return {}
      }
      let datasets = []

      let data = []
      for (let i = 0; i < this.buildingsWithData.length; i++) {
        const building = this.buildingsWithData[i]
        // TODO: Scale radius automatically according to set distribution
        data.push({
          r: Math.min(20, Math.sqrt(building.energy_area) / 7),
          x: (building.pe) / building.energy_area,
          y: (building.ghg) / building.energy_area
        })
      }
      const highlightBuildingId = this.highlightBuildingId
      datasets.push({
        backgroundColor: this.buildingsWithData.map((b) => b.id === highlightBuildingId ? 'hsla(202, 92%, 24%, 100%)' : (b.selected ? 'hsla(202, 92%, 14%, 10%)' : 'hsla(202, 92%, 14%, 8%)')),
        borderColor: this.buildingsWithData.map((b) => b.selected ? 'hsla(202, 92%, 14%, 30%)' : 'hsla(202, 92%, 14%, 10%)'),
        hoverBackgroundColor: 'hsla(20, 92%, 44%, 25%)',
        hoverBorderColor: 'hsla(20, 92%, 44%, 70%)',
        data: data
      })

      return {
        datasets: datasets
      }
    }
  }
}
</script>
